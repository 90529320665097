import request from "@/utils/request";

// 新增员工
export function addStaff(data) {
  return request("post", "/aps/user/addEmployees", data);
}
// 删除员工
export function deleteStaff(data) {
  return request("post", "/aps/user/delEmployees", data);
}
// 修改员工
export function updateStaff(data) {
  return request("post", "/aps/user/modEmployees", data);
}
// 查询员工详情

export function getStaffDetail(data) {
  return request("post", "/aps/user/findEmployees", data);
}
// 删除员工
export function delStaff(data) {
  return request("post", "/aps/user/delEmployees", data);
}
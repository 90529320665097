<template>
  <div class="staff-container">
    <section class="table-search">
      <div class="search-L">
        <el-input v-model="tableParams.kw" placeholder="请输入名称或手机号搜索" @input="kwChange" class="mr15" clearable />
        <el-cascader v-model="tableParams.deptIds" :options="userDepartmentList" placeholder="请选择部门" class="mr15"
          :props="props" @change="handleChange($event, 1)" collapse-tags :show-all-levels="false"
          clearable></el-cascader>

        <el-select v-model="tableParams.roleIds" placeholder="请选择岗位" @change="reload" clearable multiple collapse-tags>
          <el-option v-for="item in postList" :key="item.roleId" :label="item.roleName" :value="item.roleId">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" v-has="'staff_add'" @click="onAdd()">添加员工</el-button>
      </div>
    </section>
    <Table :tableData="tableData" :column="staffColumns" border :headerCellStyle="headerCellStyle">
      <template slot-scope="scope" slot="sexSlot">
        <span>{{ sexObj[scope.row.sex] || "-" }}</span>
      </template>
      <template slot-scope="scope" slot="operation" v-if="scope.row.isLoginUser == false">
        <el-button type="text" size="medium" @click.stop="onEdit(scope.row)" v-has="'staff_edit'">编辑</el-button>
        <el-button type="text" size="medium" @click.stop="onDel(scope.row)" v-has="'staff_del'">删除</el-button>
      </template>
    </Table>
    <div class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog :visible.sync="editShow" :title="editTitle" :close-on-click-modal="false" width="30%" center>
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="120px">
        <el-form-item label="姓名" prop="userName">
          <el-input v-model.trim="params.userName" autocomplete="off" maxlength="16" placeholder="请填写姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号（账号）" prop="mobile">
          <el-input type="number" v-model="params.mobile" autocomplete="off" placeholder="请填写11位手机号"></el-input>
        </el-form-item>
        <el-form-item label="选择性别" prop="sex">
          <el-select v-model="params.sex" placeholder="请选择性别">
            <el-option v-for="item in ObjToOpt(sexObj)" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="归属部门" prop="deptIds">
          <el-cascader v-model="params.deptIds" :show-all-levels="false" :options="userDepartmentList" collapse-tags
            placeholder="请选择部门" multiple :props="props" @change="handleChange($event, 2)" class="noClose"></el-cascader>
        </el-form-item>
        <el-form-item prop="roleIds">
          <template slot="label">
            <span style="color: #f56c6c;margin-right: 4px;">*</span><span>门店权限/岗位</span>
          </template>
          <el-cascader v-model="params.roleIds" :options="deptRoleList" ref="downLoadLvlRef" placeholder="请选择门店权限及岗位"
            :props="roleProps" class="noClose" collapse-tags>
          </el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { staffColumns } from "@/db/tableColumns";
import { queryDept } from "@/api/organization/department.js";
import {
  addStaff, getStaffDetail, delStaff, updateStaff
} from "@/api/organization/staff";
import {
  getUserPage,
  getRoleList,
} from "@/api/account/account";
import {
  queryDeptAuth, queryRoleByDept
} from "@/api/organization/department.js";
import {
  sexObj,
  isAdminObj,
  isAdminColor
} from "@/db/objs"
import {
  ObjToOpt,
} from "@/utils/utils"
export default {
  data() {
    return {
      myValue: ['2', '3'],
      staffColumns,
      headerCellStyle: {
        background: '#FAFAFA',
        color: '#000000',
      },
      cascaderProps: {
        value: 'deptId',
        label: 'deptName',
        children: 'childDeptVoList',
        emitPath: false,
        checkStrictly: true
      },
      props: {
        value: 'deptId',
        label: 'deptName',
        children: 'childDeptVoList',
        multiple: true,
        checkStrictly: true,
        emitPath: false,
        disabled: 'disabled'
      },
      roleProps: {
        value: 'treeId',
        label: 'treeName',
        children: 'roleVoList',
        multiple: true,

      },
      rules: {
        userName: [{ required: true, message: "请填写姓名", trigger: "blur", }],
        mobile: [
          { required: true, message: "请填写联系方式", trigger: "blur", },
          { min: 11, max: 11, essage: "请填写正确的手机号（长度为11位）", trigger: "blur", },
        ],
        deptIds: [{ required: true, message: '请选择部门', trigger: 'change' }]
      },
      sexObj,
      ObjToOpt,
      isAdminObj,
      isAdminColor,
      // table列表
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],
      tableParams: {
        page: 1,
        size: 10,
        kw: "",
        deptIds: [],
        roleIds: [],
      },
      // 表单
      editShow: false, //弹框开关
      editTitle: "", //弹框姓名
      departmentList: [],//部门列表
      postList: [],//岗位列表
      params: {
        userId: "", //用户ID
        userName: "", //姓名
        mobile: "", //联系方式
        isAdmin: 0, //类型
        sex: 0, //性别

      },
      userDepartmentList: [], //用户所在部门
      deptRoleList: [], //部门岗位
      roleShopVoList: [], //选中的岗位

    };
  },
  created() {
    // this.queryDeptList()
    this.getUserDeptList();
  },
  mounted() {
    this.getUserList();
    this.getRoleList();
  },
  methods: {
    // 查询部门列表
    // queryDeptList() {
    //   const params = {
    //     deptStatus: ''
    //   };
    //   queryDept(params).then(res => {
    //     this.departmentList = [res.data];
    //   });
    // },
    // 【请求】岗位列表
    getRoleList() {
      let data = {
        page: 1,
        size: 1000,
        roleName: "",
      };
      getRoleList(data).then((res) => {
        this.postList = res.data.list;
      });
    },
    // 查询当前登录用户的部门列表
    getUserDeptList() {
      queryDeptAuth().then(res => {
        this.userDepartmentList = [res.data];
      })
    },
    // 查询当前部门的岗位列表
    getDeptRoleList(ids) {
      const params = {
        deptIds: ids
      }
      queryRoleByDept(params).then(res => {
        this.deptRoleList = res.data;
      })
    },

    // 【请求】用户列表
    getUserList() {
      let data = JSON.parse(JSON.stringify(this.tableParams));
      data.roleIds = data.roleIds.join(',');
      getUserPage(data).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    // 【请求】查询员工详情
    getStaffDetail(userId) {
      getStaffDetail({ userId: userId }).then(res => {
        if (res.isSuccess == "yes") {
          this.deptRoleList = res.data.shopRoleVoList;
          this.params = res.data;
          if (this.params.deptIds && this.params.deptIds.length > 0) {
            this.params.roleIds = res.data.userRoleList.map(item => [item.shopId, item.roleId]);
          }
          console.log(this.params)
        }
      })
    },
    // 【请求】编辑账户
    getUserRoleEdit() {
      updateStaff(this.params).then(res => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.roleShopVoList = [];
          this.$message({
            message: '编辑成功',
            type: "success",
          });
          this.reload()
        }
      })
    },
    // 添加员工
    getUserRoleAdd() {
      addStaff(this.params).then(res => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.roleShopVoList = [];
          this.$message({
            message: '添加成功',
            type: "success",
          });
          this.reload()

        }
      }
      )
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getUserList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getUserList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】新增用户
    onAdd() {
      this.editShow = true;
      // this.getUserDeptList();
      this.editTitle = "添加员工";
      this.params = {
        userId: "", //用户ID
        userName: "", //姓名
        mobile: "", //联系方式
        roleIds: [], //岗位
        isAdmin: 0, //类型
        sex: 0, //性别
      }
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑按钮
    onEdit(row) {
      this.editShow = true;
      // this.getUserDeptList();
      this.editTitle = "编辑员工";
      this.getStaffDetail(row.userId);
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmit: _.debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.roleIds == null || this.params.roleIds.length == 0) {
            this.$message.error('请选择门店权限/岗位');
            return false;
          }
          let downLoadLvl = this.$refs.downLoadLvlRef.getCheckedNodes(true)
          this.params.roleShopVoList = downLoadLvl.map(item => item.data)
          if (this.params.userId) {
            this.getUserRoleEdit();
          } else {
            this.getUserRoleAdd()
          }
        } else {
          return false;
        }
      });

    }, 500),

    // 【监听删除】
    onDel(row) {
      let data = {
        userId: row.userId,
      }
      this.$confirm('请确保已做好员工工作交接，是否确定删除?', '删除员工', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        delStaff(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getUserList();
          }
        })
      });
    },
    handleChange(value, type) {
      console.log(value, type);
      if (type == 1) {
        // 用于头部change搜索列表
        this.getUserList()
      } else {
        if (value && value.length == 0) {
          this.deptRoleList = [];
          this.params.roleIds = [];
          this.tableParams.roleIds = [];
        }
        this.getDeptRoleList(value)
      }

    },



  },
};
</script>

<style lang="scss" scoped>
.staff-container {
  padding: .15rem;
  box-sizing: border-box;

  .noClose {
    /deep/.el-tag__close {
      display: none !important;
    }
  }
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;

  /deep/.el-cascader__tags .el-tag {
    max-width: 70% !important;
  }
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}
</style>